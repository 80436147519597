import {
  Box,
  HeadingDisplay,
  HeadingH3,
  HeadingSubline,
  Link,
  ListItem,
  OrderedList,
  Text,
} from '@hausgold/designsystem';

import { Trans, TransProps, useTranslation } from 'react-i18next';
import type { ParseKeys, Namespace } from 'i18next';

interface StyledTransProps
  extends Pick<
    TransProps<ParseKeys<Namespace>, Namespace>,
    'i18nKey' | 'components' | 'ns'
  > {
  styleProps?: object;
}

/*
 * StyledTrans
 *
 * Provides content rendering configuration for react-i18next's `<Trans />`
 * component with styling from our design system.
 *
 * Any customization can be done via passing the `components` prop.
 * @see https://react.i18next.com/latest/trans-component
 */
const StyledTrans = ({
  i18nKey,
  components,
  styleProps,
  ns,
}: StyledTransProps) => {
  const { t, i18n } = useTranslation();
  return (
    <Trans
      t={t}
      ns={ns}
      i18n={i18n}
      i18nKey={i18nKey}
      components={{
        h1: <HeadingDisplay {...styleProps} />,
        h2: <HeadingSubline as="h2" {...styleProps} />,
        h3: <HeadingH3 {...styleProps} />,
        a: <Link {...styleProps} />,
        ol: <OrderedList spacing={2} mb={6} {...styleProps} />,
        li: <ListItem {...styleProps} />,
        p: <Text {...styleProps} />,
        small: <Text fontSize="sm" mt={5} {...styleProps} />,
        b: <Text fontWeight="bold" as="span" mb={0} {...styleProps} />,
        address: <Box fontStyle="normal" as="address" {...styleProps} />,
        ...components,
      }}
    />
  );
};

export default StyledTrans;
