import { useMemo, useState } from 'react';
import appsignal, { CATEGORIES } from 'app-lib/appsignal';
import NextLink from 'next/link';
import { Flex, HeadingH1, Link, LoginForm } from '@hausgold/designsystem';
import {
  useLoginMutation,
  useStartUserActivation,
} from 'app-services/queries/identityApiQueries';
import { useTranslation } from 'react-i18next';
import Page from 'app-components/Page';
import StyledTrans from 'app-components/misc/StyledTrans';

// Known specific error messages we support.
const ERROR_CODES = {
  tooMany: 'tooMany',
  inactive: 'inactive',
};

/**
 * User login component
 *
 * @returns
 */
const LoginPage = () => {
  const { mutateAsync: login, isLoading } = useLoginMutation();
  const { mutateAsync: startAccountActivation } = useStartUserActivation();

  const { t } = useTranslation();

  const [isInvalid, setIsInvalid] = useState(false);

  const translations = useMemo(() => {
    let userError = t('login.error.generic');

    if (isInvalid === ERROR_CODES.tooMany) {
      userError = t('login.error.tooManyBadAttempts');
    }

    if (isInvalid === ERROR_CODES.inactive) {
      userError = t('login.error.inactive');
    }

    return {
      error: {
        text: userError,
      },
      email: {
        label: t('form:email.label'),
        error: {
          required: t('form:email.error.required'),
          email: t('form:email.error.email'),
        },
      },
      password: {
        label: t('form:password.label'),
        error: {
          required: t('form:password.error.required'),
        },
      },
      submit: t('login.submit'),
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInvalid]);

  // Execute login request to identity-api and catch api errors for the user.
  const loginUser = async (data) => {
    const { email, password } = data;

    appsignal.addBreadcrumb({
      action: 'User made login request',
      category: CATEGORIES.UI,
    });

    const result = await login({ email, password });

    // Too many bad login attempts
    if (result?.response?.data?.code === 202) {
      setIsInvalid(ERROR_CODES.tooMany);
      // The account is inactive
    } else if (result?.response?.data?.reason === 'inactive') {
      setIsInvalid(ERROR_CODES.inactive);
      // Restart account activation.
      startAccountActivation({ email }).catch(() => {});
    } else {
      // For generic errors we do not name like mismatching credentials (code 200).
      setIsInvalid(true);
    }
  };

  /**
   * Render component
   */
  return (
    <Page title={t('login.title')} layout="minimal">
      <HeadingH1 mb={6}>{t('login.headline')}</HeadingH1>
      <LoginForm
        onSubmit={loginUser}
        isInvalid={isInvalid}
        isLoading={isLoading}
        translations={translations}
      />
      <Flex justifyContent="center" mt={5}>
        <NextLink href="/reset-password" passHref>
          <Link fontSize="sm" data-testid="reset-password-link">
            {t('common:forgotYourPassword')}
          </Link>
        </NextLink>
      </Flex>

      {/* Terms and conditions link */}
      <StyledTrans i18nKey="login.termsAndConditions" />
    </Page>
  );
};

export default LoginPage;
